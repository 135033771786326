import React, { useState, useEffect } from "react";
import Hidden from "@material-ui/core/Hidden";
import Img from "gatsby-image";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTransition, animated } from "react-spring";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import { useBreadcrumb } from "../../hooks";
import ContainerBox from "../containerBox";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { MAIN_VIDEO_LINK } from "./MainJumbotoron";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "720px",
    },
    backgroundPlaceholder: {
      width: "100%",
      height: "720px",
      objectFit: "cover",
      zIndex: -1,
      position: "absolute",
      top: 0,
    },
    container: {
      paddingTop: "220px",
      display: "flex",
      backgroundColor: "rgba(0,0,0, 0.5)",
      height: "100%",
      position: "absolute",
      top: 0,
    },
    head: {
      color: "white",
      fontWeight: 700,
      fontSize: "62px",
      marginBottom: "4px",
    },
    lead: {
      color: "white",
      fontSize: "22px",
      fontWeight: 400,
      marginBottom: "50px",
      maxWidth: "700px",
    },
    breadcrumb: {
      fontSize: "16px",
      color: "#fff",
      marginBottom: "30px",
    },
    playButton: {
      padding: 0,
    },
    playIcon: {
      height: "60px",
      width: "60px",
      color: "#fff",
    },
  })
);

const TITLE = [
  ({ style }: { style: CSSProperties }) => (
    <animated.span style={{ ...style }}>Intellectual property</animated.span>
  ),
  ({ style }: { style: CSSProperties }) => (
    <animated.span style={{ ...style }}>Intellectual property</animated.span>
  ),
];
const DESCRIPTION = [
  ({ style }: { style: CSSProperties }) => (
    <animated.span style={{ ...style }}>
      Below is a non-exhaustive list of trademarks and service marks owned by{" "}
      <br />
      Hyperconnect LLC
    </animated.span>
  ),
];

function IntellectualProPertyJumbotron() {
  const classes = useStyles();
  const [index, set] = useState(0);
  const data = useStaticQuery(graphql`
    query {
      backgroundPlaceholder: file(
        relativePath: { eq: "company/img-title-photo-company.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileBackgroundPlaceholder: file(
        relativePath: { eq: "company/mobile-title-photo-company.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const transitions = useTransition(index, p => p, {
    from: { opacity: 0, display: "none" },
    enter: { opacity: 1, display: "block" },
    leave: { opacity: 0, display: "none" },
  });

  const breadcrumb = useBreadcrumb();

  return (
    <BackgroundImage
      key={uuidv4()}
      Tag="section"
      className={classes.root}
      fluid={data.backgroundPlaceholder.childImageSharp.fluid}
    >
      <Hidden smDown>
        <video className={classes.backgroundPlaceholder} loop>
          <source src={MAIN_VIDEO_LINK} type="video/mp4" />
        </video>
      </Hidden>
      <Hidden smUp>
        <Img
          className={classes.backgroundPlaceholder}
          fluid={data.mobileBackgroundPlaceholder.childImageSharp.fluid}
        />
      </Hidden>
      <ContainerBox className={classes.container}>
        <div>
          <Typography className={classes.breadcrumb}>
            About &gt; Intellectual property
          </Typography>
          <Typography className={classes.head} variant="h1" gutterBottom>
            {transitions.map(({ item, props, key }) => {
              const Title = TITLE[item];
              return <Title key={key} style={props} />;
            })}
          </Typography>
          <Typography component="p" className={classes.lead} gutterBottom>
            {transitions.map(({ item, props, key }) => {
              const Desc = DESCRIPTION[item];
              return <Desc key={key} style={props} />;
            })}
          </Typography>
          <IconButton
            className={classes.playButton}
            href={MAIN_VIDEO_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <PlayCircleFilledIcon className={classes.playIcon} />
          </IconButton>
        </div>
      </ContainerBox>
    </BackgroundImage>
  );
}

export default IntellectualProPertyJumbotron;
